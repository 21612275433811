@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=B612:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #254cdd;
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}


#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 50px;
  line-height: 16px;
}

#mintButton {
  margin-top: 10px;
  background-color: #3E92CC;
  color:white;
}

#mintButton:disabled {
  border-color: #5b5d64;
  background-color: #231F20;
}